export default {
  message: {
    title: 'Card Title',
    text: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
    pagelength: 'Hiển thị từ 1 đến',
    of: 'trong',
    pageText2: 'mục',
    seachLabel: 'Tìm kiếm',
    SearchPlaceholder: 'Tìm kiếm',
    tableHeader: {
      name: 'Tên',
      email: 'Email',
      date: 'Ngày tháng',
      salary: 'Lương',
      status: 'Trạng thái',
      action: 'Hành động',
    },
  },
  'UI Elements': 'UI Elements',
  'Forms & Tables': 'Forms & Tables',
  Pages: 'Pages',
  'Charts & Maps': 'Biểu đồ & Bản đồ',
  Others: 'Khác',
  Typography: 'Kiểu chữ',
  Colors: 'Chọn màu',
  Feather: 'Biểu tượng',
  Cards: 'Thẻ',
  Basic: 'Basic',
  Advance: 'Nâng cao',
  Statistic: 'Bảng thống kê',
  Analytic: 'Bảng phân tích',
  'Card Action': 'Card Action',
  Components: 'Components',
  Alert: 'Alert',
  Aspect: 'Aspect',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Breadcrumb: 'Breadcrumb',
  Button: 'Button',
  'Button Group': 'Button Group',
  'Button Toolbar': 'Button Toolbar',
  Calendar: 'Calendar',
  Carousel: 'Carousel',
  Collapse: 'Collapse',
  Dropdown: 'Dropdown',
  Embed: 'Embed',
  Image: 'Image',
  'List Group': 'List Group',
  Media: 'Media',
  Modal: 'Modal',
  Nav: 'Nav',
  Overlay: 'Overlay',
  Pagination: 'Pagination',
  'Pagination Nav': 'Pagination Nav',
  Pill: 'Pill',
  'Pill Badge': 'Pill Badge',
  Popover: 'Popover',
  Progress: 'Progress',
  Sidebar: 'Sidebar',
  spinner: 'spinner',
  Tab: 'Tab',
  Time: 'Time',
  Toasts: 'Toasts',
  Tooltip: 'Tooltip',
  Extensions: 'Extensions',
  'Sweet Alert': 'Sweet Alert',
  'Quill Editor': 'Quill Editor',
  'Drag & Drop': 'Drag & Drop',
  Swiper: 'Swiper',
  Clipboard: 'Clipboard',
  'Video Player': 'Video Player',
  'Context Menu': 'Context Menu',
  Toastification: 'Toastification',
  I18n: 'I18n',
  Slider: 'Slider',
  Tour: 'Tour',
  'Auto Suggest': 'Auto Suggest',
  Tree: 'Tree',
  'Date Time Picker': 'Date Time Picker',
  'Vue Select': 'Vue Select',
  'Forms Elements': 'Forms elements',
  Select: 'Select',
  Switch: 'Switch',
  Checkbox: 'Checkbox',
  Radio: 'Radio',
  Input: 'Input',
  Textarea: 'Textarea',
  Spinbutton: 'Spinbutton',
  'Input Group': 'Input Group',
  'Form Rating': 'Form Rating',
  'Form Tag': 'Form Tag',
  'Form Datepicker': 'Form Datepicker',
  'Form Timepicker': 'Form Timepicker',
  'File Input': 'File Input',
  'Input Mask': 'Input Mask',
  'Form Layout': 'Form Layout',
  'Form Wizard': 'Form Wizard',
  'Form Validation': 'Form Validation',
  'Form Repeater': 'Form Repeater',
  'BS Table': 'BS Table',
  'Good Table': 'Good Table',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  Echart: 'Echart',
  Leaflet: 'Leaflet',
  Profile: 'Profile',
  'Account Settings': 'Cài đặt tài khoản',
  'Agency Settings': 'Cài đặt thông tin đại lý',
  'Change Password': 'Đổi mật khẩu',
  Faq: 'Faq',
  'Knowledge Base': 'Knowledge Base',
  Pricing: 'Pricing',
  Blog: 'Blog',
  List: 'List',
  Detail: 'Chi tiết',
  Edit: 'Sửa',
  Search: 'Tìm kiếm',
  Results: 'Kết quả tìm kiếm',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  Support: 'Support',
  'Raise Support': 'Raise Support',
  Documentations: 'Tài liệu',
  Dashboards: 'Bảng điều khiển',
  eCommerce: 'eCommerce',
  Analytics: 'Thống kê',
  'Apps & Pages': 'Apps & Pages',
  Email: 'Email',
  Chat: 'Chat',
  Todo: 'Todo',
  Invoice: 'Hóa Đơn',
  'Invoice List': 'Danh sách hóa đơn',
  'Invoice Create': 'Lập hoá đơn',
  'Invoice Detail': 'Chi tiết hoá đơn',
  'Invoice Replace': 'Thay thế hoá đơn',
  'Invoice Adjust': 'Điều chỉnh hoá đơn',
  'Invoice Preview': 'Xem hoá đơn',
  'Certificate Manage': 'Cài đặt chứng thư số',
  'Release Registration': 'Đăng ký phát hành',
  Declaration: 'Tờ khai',
  'Authorization Declaration': 'Tờ khai uỷ nhiệm',
  'Invoice Template': 'Mẫu hoá đơn',
  Preview: 'Preview',
  Add: 'Add',
  Shop: 'Shop',
  Details: 'Details',
  Wishlist: 'Wishlist',
  Checkout: 'Checkout',
  User: 'User',
  View: 'View',
  Authentication: 'Authentication',
  'Login v1': 'Login v1',
  'Login v2': 'Login v2',
  'Register v1': 'Register v1',
  'Register v2': 'Register v2',
  'Forget Password v1': 'Forget Password v1',
  'Forget Password v2': 'Forget Password v2',
  'Forgot Password v1': 'Forgot Password v1',
  'Forgot Password v2': 'Forgot Password v2',
  'Reset Password v1': 'Reset Password v1',
  'Reset Password v2': 'Reset Password v2',
  Miscellaneous: 'Miscellaneous',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Lỗi',
  Statistics: 'Statistics',
  'Card Actions': 'Card Actions',
  'Media Objects': 'Media Objects',
  Timeline: 'Timeline',
  'Access Control': 'Access Control',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Mail Templates': 'Mail Templates',
  Welcome: 'Welcome',
  'Reset Password': 'Reset Password',
  'Verify Email': 'Verify Email',
  'Deactivate Account': 'Deactivate Account',
  Promotional: 'Promotional',
  'Page Layouts': 'Page Layouts',
  'Collapsed Menu': 'Collapsed Menu',
  'Layout Boxed': 'Layout Boxed',
  'Without Menu': 'Without Menu',
  'Layout Empty': 'Layout Empty',
  'Layout Blank': 'Layout Blank',
  Case: 'Case',
  Queue: 'Queue',
  'Preview temp': 'Preview temp',
  10: '10',

  Booking: 'Đặt vé',
  Bookings: 'Đặt vé',
  Reservations: 'Danh sách đặt chỗ',
  'Reservation Detail': 'Chi tiết đặt chỗ',
  'Reservation Ticket': 'Mặt vé',
  HOLD: 'Giữ chỗ',
  PAID: 'Đã thanh toán',
  CANCEL: 'Đã huỷ',
  EXPIRED: 'Quá hạn',

  Customer: 'Khách hàng',
  Employee: 'Nhân viên',
  Agency: 'Đại lý',
  Contract: 'Hợp đồng',
  Receipt: 'Biên lai',
  Receipts: 'Biên lai',
  Document: 'Tài liệu',
  Account: 'Tài khoản',
  Documents: 'Documents',

  'Create New Employee': 'Thêm nhân viên',
  'Employee management': 'Quản lý nhân viên',
  'Create New': 'Thêm mới',
  'Employee detail': 'Chi tiết nhân viên',
  'Employee List': 'Danh sách nhân viên',
  'Create New Customer': 'Thêm khách hàng',
  'Customer management': 'Quản lý khách hàng',
  'Customer detail': 'Chi tiết khách hàng',
  'Customer List': 'Danh sách khách hàng',
  'Contact Add': 'Thêm liên hệ',
  Contacts: 'Liên hệ',
  'Contact Edit': 'Chỉnh sửa liên hệ',
  'Create New Agency': 'Thêm đại lý',
  'Agency Management': 'Quản lý đại lý',
  'Agency Detail': 'Chi tiết đại lý',
  'Agency List': 'Danh sách đại lý',
  'Create New Receipt': 'Tạo biên lai',
  'Receipt Management': 'Quản lý biên lai',
  'Receipt Detail': 'Thông tin biên lai',
  'Receipt List': 'Danh sách biên lai',
  Flight: 'Đặt chuyến bay',
  'Flight v2': 'Đặt chuyến bay',
  general: 'Thông tin chung',
  changePassword: 'Đổi mật khẩu',
  'Search flights': 'Tìm kiếm chuyến bay',
  Tickets: 'Vé',
  'Error Notification': 'Thông báo sai sót',
  'Accounts payable general': 'Công nợ tổng hợp',
  'Accounts payable': 'Công nợ',
  'Account Payable Retail': 'Công nợ tổng hợp bán lẻ',
  'Accounts payable list': 'Danh sách công nợ',
  'Detail accounts payable': 'Chi tiết công nợ',
  'Update accounts payable': 'Cập nhật công nợ',
  'Import accounts payable': 'Nhập từ Excel',
  'Sale Report': 'Báo cáo bán chi tiết',
  'Sale Report Retail': 'Báo cáo bán chi tiết bán lẻ',
  'Sale Report Refund Ticket': 'Hoàn định danh VJ',

  Role: 'Vai trò',

  // ANCHOR: Configs
  Settings: 'Cài đặt',
  'My account': 'Tài khoản',
  'Agency account': 'Thông tin đại lý',
  Configs: 'Cài đặt',
  'Fee Configs': 'Phí xuất vé cho',
  'Service Fee Configs': 'Phí dịch vụ khách lẻ',
  electronicTicket: 'Cài đặt mặt vé',
  configsDisplay: 'Cài đặt hiển thị',
  'Send Notification': 'Gửi thông báo',
  // ANCHOR: Sale report
  'Create sale report manually': 'Tạo báo cáo bán thủ công',
  'Sale report detail': 'Chi tiết báo cáo bán',

  Promotion: 'Hoa hồng',
  // ANCHOR: Promotion AG
  'Promotion AG': 'Hoa hồng AG',
  'Promotion AG List': 'Danh sách hoa hồng AG',
  'Create New Promotion AG': 'Thêm mới hoa hồng AG',
  'Promotion AG detail': 'Chi tiết hoa hồng AG',

  // ANCHOR: Promotion Airline
  'Promotion Airline': 'Khuyến mãi hãng',
  'Promotion Airline List': 'Danh sách khuyến mãi hãng',
  'Create New Promotion Airline': 'Thêm mới khuyến mãi hãng',
  'Promotion Airline Detail': 'Chi tiết khuyến mãi hãng',

  // ANCHOR: Promotion User
  'Promotion User': 'Khuyến mãi hãng theo tài khoản',
  'Promotion User List': 'Danh sách khuyến mãi hãng theo tài khoản',
  'Create New Promotion User': 'Thêm khuyến mãi hãng theo tài khoản',
  'Promotion User Detail': 'Chi tiết khuyến mãi hãng theo tài khoản',

  // ANCHOR Other
  'AIRDATA pending': 'AIRDATA pending',
  Template: 'Template',

  // ANCHOR : Work Schedule
  'Work Schedule': 'Lịch trực',
  'Work Schedule List': 'Danh sách lịch trực',
  'Create Work Schedule': 'Tạo lịch trực',

  // ANCHOR: Airlines 1G can pay
  'Airlines 1G can pay': 'Hãng xuất vé',

  // ANCHOR Document Management cong van tai lieu
  'Document Management': 'Quản lý tài liệu',
  'Folder list': 'Danh sách thư mục',
  'Document list': 'Danh sách tài liệu',
  'Create New Document': 'Thêm mới tài liệu',
  'Document Detail': 'Chi tiết tài liệu',

  // ANCHOR: Flight Preparation list
  'Flight Preparation': 'Vé sắp bay',
  'Flight Preparation List': 'Danh sách Vé sắp bay',

  // ANCHOR Agency Balance
  'Agency Balance': 'Số dư hãng',

  // ANCHOR Find Booking Vietjet
  'Find Booking Vietjet': 'Tra cứu vé Vietjet',

  // ANCHOR retail
  Retail: 'Bán lẻ',
  'Retail Report': 'Báo cáo tổng hợp',
  'Retail Bookings': 'Danh sách đặt chỗ bán lẻ',
  'Retail Config Service Fee': 'Cài đặt phí dịch vụ',

  // ANCHOR booking statistic
  'Booking Statistic': 'Thống kê giữ chỗ',
  'Low Fare Tracking Statistic': 'Quản lý canh vé rẻ',
  'Past Day Booking List': 'Danh sách vé qua ngày bay',
  Terminals: 'Terminals',

  'Reference Data Search': 'Tra cứu thông tin dữ liệu',
  Utils: 'Tiện ích',
  'Agency package configs': 'Các gói đang hoạt động',
  'Agency package history': 'Lịch sử sử dụng',
  'Agency package order': 'Quản lý đơn hàng',
  'Package configs': 'Gói dịch vụ bán lẻ',
  'Package configs create': 'Tạo gói dịch vụ bán lẻ',
  'Package configs detail': 'Chi tiết gói dịch vụ bán lẻ',
  'Package Coupons': 'Mã giảm giá gói bán lẻ',
  'Check-in cancellation VNA': 'Gỡ checkin VNA',
  titleRepicient: 'Yêu cầu nạp tiền',
  titleSender: 'Nạp tiền',
  officialDispatch: 'Công văn',
  'Resell Ticket': 'Shop định danh',
  'Search resell Ticket': 'Danh sách định danh',
  'Manager resell Ticket': 'Quản lý định danh',
  sepayManager: 'Tích hợp sepay',
  'Bank accounts': 'Tài khoản ngân hàng',
  Transactions: 'Giao dịch',
  Companies: 'Tổ chức',
  suggestShopVJ: 'Gợi ý định danh',
  'Support Request': 'Yêu cầu hỗ trợ',
}
