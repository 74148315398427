export default {
  addAgency: 'Agency',
  agencies: 'agencies',

  checkbox: '',
  agencyCode: 'Agency Code',
  agencyInformation: 'Agency Information',
  accountsPayableTemp: 'Temporary debt',
  taxCode: 'Tax Code',
  created: 'Created',
  updated: 'Updated',
  status: 'Contract Status',
  activated: 'Operation Status',
  agencyFee: 'Agency fee',
  retail: 'Retail',
  manager: 'Manager',
  createdBy: 'Created by',
  updatedBy: 'Updated by',

  STT: 'STT',
  avatar: 'Avatar',
  agencyName: 'Agency name',
  agencyOwner: 'Representative Name',
  emailAddress: 'Email address',
  sendMonthlyReport: 'Send monthly report',
  agencyManager: 'Agency manager',
  placeholderAgencyManager: 'Select agency manager',

  errFetchAgency: 'Error fetching agencies data',
  notFundAgencyById_1: 'No agencies found with this agencies id. Check',
  notFundAgencyById_2: 'agencies List',
  notFundAgencyById_3: 'for other agencies.',
  customers: 'Customers',

  agencyDetail: 'Agency Detail',
  editAgency: 'Edit Agency',
  agencyInfo: 'Agency basic information',

  placeholderSearch: 'Name, email, phone...',
  labelName: 'Agency name',
  placeholderName: 'Enter agency name',
  labelOwner: 'Agency owner',
  placeholderOwner: 'Enter agency owner',
  labelOwnerPhone: 'Agency owner phone number',
  placeholderOwnerPhone: 'Enter agency owner phone number',
  uploadFrontId: 'Upload the photo of ID front face',
  uploadBackId: 'Upload the photo of ID back face',
  labelAirline: 'Airline',
  placeholderAirline: 'Select airline',
  labelEmail: 'Email',
  placeholderEmail: 'Enter email',
  labelStatus: 'Status',
  placeholderStatus: 'Select status',
  labelUsername: 'Username',
  placeholderUsername: 'Enter username',
  agencyBusinessInfo: 'Agency business information',
  labelHolderName: 'Holder name',
  placeholderHolderName: 'Enter holder name',
  labelIdentityCardNum: 'Identity card number',
  placeholderIdentityCardNum: 'Enter identity card number',
  labelIdentityCardName: 'Identity card name',
  placeholderIdentityCardName: 'Enter identity card name',
  labelPlaceOfIssue: 'Place of issue',
  placeholderPlaceOfIssue: 'Enter place of issue',
  labelMonthlyReport: 'Send monthly report',
  placeholderMonthlyReport: 'Select send monthly report',
  labelTaxCode: 'Tax code',
  placeholderTaxCode: 'Enter tax code',
  airAccountForAgencyText: 'Air account for ggency',
  provideAccount:
    'Please provide an air account from the airline to the agency:',
  labelAirUsername: 'Air account username',
  placeholderAirUsername: 'Default air account username',
  labelLimitCredit: 'Limit credit',
  labelWarningCredit: 'Warning credit',
  changeLimit: 'Change limit',
  inputLimit: 'Enter the limit credit to change',

  errFetchAgencyData: 'Error fetching new agency data',
  goToAgency_1: 'Go to',
  goToAgency_2: 'Agency List',

  moreDropdown: {
    title: 'More',
    add: 'Add Agency',
    export: 'Export Customer',
    delete: 'Delete Customer',
  },

  topup: 'Topup',
  topupForAgency: 'Create request topup for agency',
  bankName: 'Bank name',
  accNumber: 'Account number',
  accHolder: 'Account Holder',
  branch: 'Branch',
  amountNumber: 'Amount Number',
  currency: 'VND',
  transferContent: 'Transfer content',
  generateQRcode: 'Generate QRcode',

  agencyCompanyName: 'Company name',
  agencyFoundingDate: 'Company founding date',
  agencyAddress: 'Company address',
  paymentMethod: 'Payment method',
  overdraft: 'Overdraft',
  prepay: 'Prepay',
  documentContract: 'Document contract',
  birthday: 'Birthday owner',
  enter: 'Enter',
  managerInfo: 'Manager Info',
  managerEmployeesTable: {
    employeeCode: 'Employee Code',
    employeeName: 'Employee Name',
    emailAddress: 'Email',
    phoneNumber: 'Phone Number',
    actions: 'Actions',
  },
  BEManager: 'Booker',
  SEManager: 'Saler',
  KTVManager: 'KTV, KTT',
  assignManagersError: 'Error assign managers',
  assignManagersSuccess: 'Assign managers success',
  notChangeYouBalance:
    'Unable to change limit balance limit by yourself, please contact a higher level agency.',
  configs: {
    hideFee: 'Hide fee',
    showFee: 'Show fee',
    sendMailAirlineOn: 'Allow sending airline email to F3',
    sendPhoneAirline: 'Allow sending airline information to F3 phone number',
    sendMailAirlineOff: 'Allow sending airline email to F3',
    title: 'Configs',
    enableSetLowFare: 'Allow set low fare tracking',
    disableSetLowFare: 'Disallow set low fare tracking',
    autoDeposit: 'Allow auto deposit',
    disableAutoDeposit: 'Disallow auto deposit',
    autoIssueTicket: 'Allow auto issue ticket',
    disableAutoIssueTicket: 'Disallow auto issue ticket',
    enableBookingGroup: 'Allow booking group',
    disableBookingGroup: 'Disallow booking group',
    usePhoneCustomer: 'Use phone customer',
    showTicketCondition: 'Show ticket condition',
    enableDraft: 'Enable draft',
    disableDraft: 'Disable draft',
    issueLimit: 'Issue limit',
    bookingLimit: 'Booking limit',
    refundLimit: 'Refund limit',
    voidLimit: 'Void limit',
    permissionTitle: 'Permission Configs',
    featureTitle: 'Feature Configs',
    retailTitle: 'Retail Configs',
    feeTitle: 'Fee Configs',
    enableQuickName: 'Enable quick name',
    enableRebook: 'Enable Rebook',
    limitPaxBooking: 'Pax/booking limit',
    limitTimeSearch: 'Time searching limit',
    showPaymentOffice: 'Payment office',
    showPaymentHome: 'Payment home',
    showPaymentQR: 'Payment QR',
    paymentMethod: 'Payment Method',
  },
  bookerId: 'ID Booker',
  bankAccountsList: {
    branch: 'Branch',
    bankCode: 'Bank name',
    bankAccountNo: 'Bank account number',
    bankAccountName: 'Bank account name',
    addBankAccount: 'Add bank account',
    editBankAccount: 'Edit bank account',
    add: 'Add',
    edit: 'Edit',
    cancel: 'Cancel',
    ph: {
      branch: 'Enter Branch',
      bankCode: 'Enter Bank name',
      bankAccountNo: 'Enter Bank account number',
      bankAccountName: 'Enter Bank account name',
    },
    isDefault: 'Default',
    action: 'Action',
  },
  employees: {
    title: 'Employee list of agency',
    columns: {
      no: 'No.',
      empCode: 'Employee code',
      empInfo: 'Employee info',
      tabInfo: 'Account info',
      created: 'Created',
      updated: 'Updated',
      status: 'Status',
      action: 'Actions',
    },
    changePassword: 'Change password',
    changePasswordTitle: 'Enter new password',
    changePasswordSuccess: 'Change password successfully!',
    changePasswordError: 'Change password error!',
    OnlyAgencyHighUp:
      "Only the current agent's superior agent can perform this action!",
    OnlyADMandOPEandSErole:
      'Only Admin, OPE or Sales have the right to perform this action!',
    plsInputPassword: 'Please input new password!',
    min6character: 'Enter at least 6 letters!',
  },
  note: 'Note',
  no: 'No',
  auditLog: 'Audit log',
  paxUpdated: 'Changer',
  updatedData: 'Changed information',
  updatedAt: 'Modified at',
  action: 'Action',
  auditLogsCredit: {
    no: 'No',
    updated: 'Modified at',
    limitCredit: 'Limit credit',
    warningCredit: 'Warning credit',
    note: 'Note',
    outstandingBalance: 'Outstanding balance',
  },
  topupRecords: {
    createdAt: 'Created at',
    confirmedBy: 'Confirmed by',
    balance: 'Balance',
    paymentAmount: 'Payment amount',
    note: 'Note',
  },

  editBalance: {
    title: 'Edit balance',
    balanceNow: 'Balance',
    limitCredit: 'Limit credit',
    editBalanceForAgency: 'Edit balance for agency',
    areYouConfirm:
      'Are you sure you want to edit the balance for this agency ?',
    msgEditSuccess: 'Edit balance successful',
    msgEditError: 'Error edit balance',
  },

  fee: {
    titleModalEdit: 'Change ticket Issuing Fee for agency',
  },

  agenciesFeeOnly: 'Ticket issuance fee for this {floor} agent only',
  confirmChange: 'Confirm change',

  modal: {
    toggleEnableSendEmployeeMailAirline:
      'Are you sure you want to change the status to enable employees to receive airline mail?',
  },
  uploadContract: 'Upload contract',
  uploadImage: 'Upload image',
  company: {
    title: 'Company',
    headerInfo: 'Company information',
    create: 'Create',
    field: {
      name: 'Company name',
      taxCode: 'Tax code',
      province: 'Province',
      district: 'District',
      ward: 'Ward',
      address: 'Address',
      phoneNumber: 'Phone number',
      email: 'Email',
      taxAuthorityCode: 'Tax authority code',
      taxAuthorityName: 'Tax authority name',
      agentName: 'Agent name',
      agentPhoneNumber: 'Agent phone number',
      active: 'Active',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
    },
    ph: {
      name: 'Enter Company name',
      taxCode: 'Enter Tax code',
      province: 'Enter Province',
      district: 'Enter District',
      ward: 'Enter Ward',
      address: 'Enter Address',
      phoneNumber: 'Enter Phone number',
      email: 'Enter Email',
      taxAuthorityCode: 'Enter Tax authority code',
      taxAuthorityName: 'Enter Tax authority name',
      agentName: 'Enter Agent name',
      agentPhoneNumber: 'Enter Agent phone number',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
    },
    noCompany:
      'Agency does not have company information yet. Please input and create.',
    activeCompany: 'Active company',
    deactiveCompany: 'Deactive company',
  },
  webConfig: {
    noWebConfig: 'The agency has not yet configured website. Please configure.',
    title: 'Web configs',
    smtpOptions: 'SMTP Config',
    field: {
      title: 'Title',
      websiteUrl: 'Website URL',
      useSeparateATS: 'Use separate ATS',
      email: 'Email Address',
      configs: 'Configs',
      logo: 'Logo',
      banner: 'Banner',
      emailType: 'Email type',
      host: 'Host',
      port: 'Port',
      username: 'Username',
      password: 'Password',
      applyForEmployee: 'Apply for employee',
      content: 'Content',
    },
    ph: {
      title: 'Title',
      websiteUrl: 'Enter Website URL',
      logo: 'Enter url or upload file',
      banner: 'Enter url or upload file',
      emailType: 'Select email type',
      email: 'Enter Email Address',
      host: 'Enter Host',
      port: 'Enter Port',
      username: 'Enter Username',
      password: 'Enter Password',
      applyForEmployee: 'Apply for employee',
      content: 'Content',
    },
    emailType: {
      'Yahoo mail': 'Yahoo Mail',
      'Google mail': 'Google mail',
      Other: 'Other',
    },
  },
}
