/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { computed } from '@vue/composition-api'

import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/api/auth/utils'
import store from '@/store'
import i18n from '@/libs/i18n'

import apps from './routes/apps'
import pages from './routes/pages'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Airdata'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  ignoreDuplicate: true,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'apps-flightsV2-page' } }, // default page
    ...apps,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Catch lỗi NavigationDuplicated
// ref link
// https://stackoverflow.com/questions/58634914/getting-navigation-duplicated-error-while-the-route-is-being-replaced-on-vue-rou/64466463#64466463
const originalPush = VueRouter.prototype.push
// eslint-disable-next-line func-names
VueRouter.prototype.push = async function (location) {
  let route
  try {
    route = await originalPush.call(this, location)
  } catch (err) {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  }
  return route
}

let reloaded = false
router.onError(error => {
  console.error('router.onError', { error })
  if (/loading chunk \d* failed./i.test(error.message)) {
    // && navigator.onLine
    console.error('loading chunk .* failed', { error })
    // eslint-disable-next-line no-alert
    alert(
      'Loading chunk failed. Make sure your network connection is working properly.',
    )
    localStorage.clear()
    window.location.reload()
  }
  if (!reloaded) {
    console.log('reloading')
    localStorage.clear()
    window.location.reload()
    reloaded = true
  }
})

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  const { employeeData } = getUserData()
  if (to.meta.resource !== 'Auth' && !isLoggedIn) {
    // eslint-disable-next-line no-underscore-dangle
    let callbackUrl = router.history._startLocation
    if (callbackUrl === '/') callbackUrl = ''
    next({ name: 'auth-login', query: callbackUrl ? { callbackUrl } : {} })
    // Redirect if logged in
  } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(employeeData || null))
  } else {
    next()
  }
  if (employeeData) {
    // SECTION Check điều kiện floor và role của user xem có được quyền truy cập hay ko
    const {
      meta: { role, floor, hideForProduction },
    } = to // lấy quyền (role, floor) được truy cập trang
    const isRoleF1 = employeeData?.agency?.id === 1000000 ? 'F1' : false
    const isRoleF2 = employeeData?.agency?.parentAgency?.id === 1000000 ? 'F2' : false
    const isRoleF3 = employeeData?.agency?.id !== 1000000
      && employeeData?.agency?.parentAgency?.id !== 1000000
      ? 'F3'
      : false
    const floorUser = isRoleF1 || isRoleF2 || isRoleF3 // F của user
    const { type } = employeeData // type của user

    if (
      (floor && !floor.includes(floorUser))
      || (role && !role.includes(type))
    ) {
      return next({ name: 'misc-not-authorized' })
    }

    // !SECTION
    // ANCHOR check hideForProduction
    if (hideForProduction && process.env.VUE_APP_ENV === 'production') {
      return next({ name: 'misc-not-authorized' })
    }

    if (to.path === '/apps/work-schedule/create') {
      const isMama = employeeData.agency?.id === 1000000
      const f2 = employeeData.agency?.parentAgency?.id === 1000000
      const f3 = !f2 && !isMama

      if (
        !to.query.type
        || f3
        || (f2 && !['ADM', 'OPE'].includes(type))
        || (isMama
          && to.query.type === 'SE'
          && !['ADM', 'OPE', 'SE'].includes(type))
        || (isMama
          && to.query.type === 'BE'
          && !['ADM', 'OPE', 'BE'].includes(type))
      ) {
        return next({ name: 'misc-not-authorized' })
      }
    }

    if (to.path === '/apps/configs/agency-setting' && type !== 'ADM') {
      return next({ name: 'misc-not-authorized' })
    }
  }

  if (!localStorage.getItem('Locale')) {
    localStorage.setItem('Locale', 'vi')
  }

  const promiseArr = []
  if (!store.getters['globalConfig/isHasConfig']) {
    promiseArr.push(store.dispatch('globalConfig/setGlobalConfig'))
  }
  if (!store.getters['userStore/hasGrantedScopes'] && !!isUserLoggedIn()) {
    promiseArr.push(store.dispatch('userStore/fetchGrantedScopes'))
  }
  if (!store.getters['userStore/hasFunctionScopes'] && !!isUserLoggedIn()) {
    promiseArr.push(store.dispatch('userStore/fetchFunctionScopes'))
  }
  if (!store.getters['userStore/hasMeData'] && !!isUserLoggedIn()) {
    promiseArr.push(store.dispatch('userStore/fetchMeData'))
  }
  if (
    !store.getters['globalConfig/getAllDistributors'].length
    && !!isUserLoggedIn()
  ) {
    promiseArr.push(
      store.dispatch('globalConfig/getAvailableDistributorSource'),
    )
  }
  if (promiseArr.length) {
    await Promise.all(promiseArr)
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  const title = computed(() => store.getters['app/getTitleSite'])
  // Change tab title when route
  document.title = to?.meta?.pageTitle
    ? `${
      i18n.te(to?.meta?.pageTitle)
        ? i18n.t(to?.meta?.pageTitle)
        : to?.meta?.pageTitle
    } - ${title.value || DEFAULT_TITLE}`
    : title.value || DEFAULT_TITLE

  return next()
})

export default router
