/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import { sortBy } from 'lodash'

import service from './service'

export default {
  fetchAllEmployees(_payload = {}) {
    return service.get('/employees', _payload)
  },

  fetchEmployeesByFilter(_payload = {}) {
    return service.post('/employees/search', _payload)
  },

  getEmployeeById(id, payload = {}) {
    return service.get(`/employees/${id}`, payload)
  },

  getEmployeeByAccountId(id, payload = {}) {
    return service.get(`/employees/account/${id}`, payload)
  },

  deleteEmployee(id = '') {
    return service.delete(`/employees/${id}`)
  },

  addEmployee(payload = {}) {
    return service.post('/employees', payload)
  },

  updateEmployee(id = '', payload = {}) {
    return service.patch(`/employees/${id}`, payload)
  },

  updateBookerId(id = '', payload = {}) {
    return service.put('/employees/booker-id', payload)
  },

  updateActive(id = '', payload = {}) {
    return service.patch(`/employees/${id}/active`, payload)
  },

  updateDeactive(id = '', payload = {}) {
    return service.patch(`/employees/${id}/deactive`, payload)
  },

  bulkDeleteEmployees(ids = {}) {
    return service.post('/employees/bulk-delete', ids)
  },

  validateEmail(payload = {}) {
    return service.post('/employees/validate-email', payload)
  },

  validatePhonenumber(payload = {}) {
    return service.post('/employees/validate-phone-number', payload)
  },

  validateUsername(payload = {}) {
    return service.post('/employees/validate-username', payload)
  },

  validateBookerId(payload = {}) {
    return service.post('/employees/validate-bookerId', payload)
  },

  exportEmployees(payload = {}, fileName) {
    return service.excel('/employees/export', payload, fileName)
  },

  exportEmployeesTemplate(fileName) {
    return service.excel('/employees/export-template', {}, fileName)
  },

  bulkInsert(payload = {}) {
    return service.post('/employees/bulk-insert', payload)
  },

  getMe() {
    return service.get('/employees/me')
  },

  assignAgencies(id, payload) {
    return service.put(`/employees/${id}/managed-agencies`, payload)
  },

  // ANCHOR SERVICE FEE
  getEmployeeProfile(employeeId) {
    return service
      .get(`/employees/${employeeId}/employee-profile`)
      .then(res => {
        res.data.forEach(profile => {
          profile.serviceFeeConfigs = sortBy(profile.serviceFeeConfigs, [
            'ticketType',
            'airline',
          ])
        })
        return res
      })
  },
  createEmployeeProfile(employeeId, payload) {
    return service.post(`/employees/${employeeId}/employee-profile`, payload)
  },
  deleteEmployeeProfile(employeeId, employeeProfileId) {
    return service.delete(
      `/employees/${employeeId}/employee-profile/${employeeProfileId}`,
    )
  },
  getServiceFee(employeeId) {
    return service.get(`/employees/${employeeId}/service-fee`)
  },
  createServiceFee(employeeId, payload) {
    return service.post(`/employees/${employeeId}/service-fee`, payload)
  },
  createServiceFeeMany(employeeId, payload) {
    return service.post(`/employees/${employeeId}/service-fee/many`, payload)
  },

  // ANCHOR Employee Config
  updateEmployeeConfig(id, payload) {
    return service.put(`/employees/${id}/update-employee-config`, payload)
  },

  // ANCHOR Payment password
  resetPaymentPassword(employeeId) {
    return service.post(`/employees/${employeeId}/reset-payment-password`)
  },

  changePaymentPassword(payload) {
    return service.post('/employees/change-payment-password', payload)
  },

  // ANCHOR MFA
  initMfa(payload) {
    return service.post('/employees/me/mfa', payload)
  },

  getAllMfa() {
    return service.get('/employees/me/mfa')
  },

  confirmAddMfa(payload) {
    return service.post('/employees/me/mfa/confirm', payload)
  },

  deleteMfaDevice(mfaId) {
    return service.delete(`/employees/me/mfa/${mfaId}`)
  },

  enableMfaLogin() {
    return service.post('/employees/me/enable-mfa-login')
  },
  disableMfaLogin() {
    return service.post('/employees/me/disable-mfa-login')
  },

  regeneratePassword(id, payload) {
    return service.post(`/employees/${id}/regenerate-user-password`, payload)
  },

  changePassword(id, payload) {
    return service.patch(`/employees/${id}/change-password`, payload)
  },
}
