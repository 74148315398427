export default {
  Document: 'Tài liệu',
  // ANCHOR: danh sách hãng xuất 1G
  airlines1gCanPay: {
    title: 'Danh sách hãng hàng không 1G được xuất vé',
    columns: {
      no: 'STT',
      airline: 'Hãng hàng không',
      adt: 'Người lớn (ADT)',
      chd: 'Trẻ em (CHD)',
      inf: 'Em bé (INF)',
      void: 'Void',
      note: 'Ghi chú',
    },
  },

  // ANCHOR: Công văn tài liệu
  folderList: 'Danh sách thư mục',
  createFolder: 'Tạo thư mục',
  renameFolder: 'Đổi tên thư mục',
  searchNameFolder: 'Tìm kiếm tên thư mục',
  searchNameDoc: 'Tìm kiếm tên tài liệu',
  folder: 'thư mục',
  deleteFolder: 'Xoá thư mục',
  folderName: 'Tên thư mục',
  message: {
    fetch: {
      error: 'Lỗi tải dữ liệu',
    },
    add: {
      folderSuccess: 'Tạo thư mục thành công',
      folderError: 'Lỗi tạo thư mục',
      documentSuccess: 'Tạo tài liệu thành công',
      documentError: 'Lỗi tạo tài liệu',
    },
    delete: {
      folderSuccess: 'Xoá thư mục thành công',
      folderError: 'Lỗi xoá thư mục',
      documentSuccess: 'Xoá tài liệu thành công',
      documentError: 'Lỗi xoá tài liệu',
    },
    update: {
      folderSuccess: 'Cập nhật thư mục thành công',
      folderError: 'Lỗi cập nhật thư mục',
      documentSuccess: 'Cập nhật tài liệu thành công',
      documentError: 'Lỗi cập nhật tài liệu',
    },
    pinSuccess: 'Treo thông báo thành công',
    pinError: 'Lỗi treo thông báo',
    unpinSuccess: 'Gỡ treo thông báo thành công',
    unpinError: 'Lỗi gỡ treo thông báo',
  },
  columns: {
    folders: {
      Folder: 'Tên thư mục',
      'Created At': 'Thời gian tạo',
      'Updated At': 'Thời gian sửa đổi',
      Action: 'Thao tác',
    },
    docs: {
      Name: 'Tên tài liệu',
      File: 'Tệp đính kèm',
      isShow: 'Công văn BGT',
      isActive: 'Trạng thái',
      status: 'Trạng thái',
      'Created At': 'Thời gian tạo',
      'Updated At': 'Thời gian sửa đổi',
      Action: 'Thao tác',
    },
  },
  file: 'Tệp đính kèm',
  createDoc: 'Tạo tài liệu',
  doc: 'tài liệu',
  infoDocs: 'Thông tin tài liệu',
  docsName: 'Tên tài liệu',
  ph: { // placeholder
    docsName: 'Nhập vào tên tài liệu',
    folder: 'Chọn thư mục',
  },
  Folder: 'Thư mục',
  docsStatus: 'Trạng thái tài liệu',
  isShowTitle: 'Công văn BGT',
  isActiveTitle: 'Đăng bài',
  isPinTitle: 'Treo thông báo',
  priorityPin: {
    title: 'Mức độ ưu tiên thông báo',
    HIGH: 'Ưu tiên cao',
    MEDIUM: 'Ưu tiên trung bình',
    LOW: 'Ưu tiên thấp',
    descHIGH: 'Độ ưu tiên cao: Thông báo được hiển thị chữ màu đỏ trên đầu danh sách.',
    descMEDIUM: '',
    descLOW: '',
  },
  forF3: 'Công khai cho đại lý F3',
  forF2: 'Chỉ dành cho đại lý F2',
  published: 'Đã đăng',
  publish: 'Đăng ngay',
  draft: 'Bản nháp',
  description: 'Nội dung',
  docDetail: 'Chi tiết tài liệu',
  docEdit: 'Chỉnh sửa tài liệu',
}
